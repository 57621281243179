@import "gerillass";
@import "../scss/variables.scss";

.footer {
  position: relative;
  height: 60vh;

  z-index: 1500;

  background-image: url("../img/bg-main.png");
  background-size: auto 100vh;
  background-attachment: fixed;

  // &::before {
  //   content: "";
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   width: 100%;
  //   height: 100%;
  //   background: #000000;
  //   opacity: 0;

  //   z-index: -1;
  // }

  @include breakpoint(max, $tablet) {
    height: 20vh;
  }

  .footerBg {
    position: absolute;
    bottom: 0;
    height: auto;
    width: 100vw;
    object-fit: cover;

    z-index: 50;

    display: block;

    @include breakpoint(max, $mobile) {
      position: relative;
      bottom: 5vh;
    }
  }

  .footerLogo {
    @include position(absolute, null 0 5vh null);
    width: 60vw;

    z-index: 75;
  }

  .actions {
    @include position(absolute, null null 10vw 5vw);

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 3rem;

    z-index: 50;

    @include breakpoint(max, $mobile) {
      @include position(relative);
    }

    h2 {
      font-family: Krungthep;
      font-size: 3.5vw;
      line-height: 140%;
      color: #ffffff;
      text-align: center;

      @include breakpoint(max, $mobile) {
        font-size: 8vw;
        line-height: 140%;
      }
    }

    button {
      background: transparent;
      padding: 1rem 5rem;
      border: 3px solid #ffffff;
      cursor: pointer;

      transition: 0.2s all ease;

      &:hover {
        box-shadow: 0px 0px 30px #a7a7a7;
        background: #000000;
      }

      @include breakpoint(max, $mobile) {
        border: 1px solid #ffffff;
        margin-bottom: 5vw;
      }

      p {
        font-family: Krungthep;
        font-size: 1.5vw;
        line-height: 140%;
        color: #ffffff;
        text-align: center;

        @include breakpoint(max, $mobile) {
          font-size: 4vw;
          line-height: 140%;
        }
      }
    }
  }

  // .backdrop {
  //   @include position(absolute, 0 null null 0);

  //   width: 100%;
  //   height: 100%;

  //   z-index: 25;
  // }

  .footerMobileBottom {
    width: 100vw;
    height: 55vh;
    background: #000000;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
  }

  .bottom {
    @include position(absolute, null 0 0 0);
    z-index: 50;

    padding: 1rem 2vw;

    display: flex;
    align-items: center;
    justify-content: space-between;

    @include breakpoint(max, $mobile) {
      position: relative;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
    }

    .copyright {
      display: flex;
      gap: 1rem;

      @include breakpoint(max, $mobile) {
        flex-direction: column-reverse;
        align-items: center;
      }

      p {
        font-family: Krungthep;
        font-size: 1.25rem;
        line-height: 100%;
        color: #ffffff;
        text-align: left;

        @include breakpoint(max, $tablet) {
          font-size: 14px;
        }

        @include breakpoint(max, $mobile) {
          line-height: 200%;
        }

        a {
          color: #ffffff;
        }
      }
    }

    .links {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 1.25rem;

      @include breakpoint(max, $mobile) {
        margin-top: 2rem;
      }
    }
  }
}
