@import "gerillass";

@font-face {
  font-family: "Krungthep";
  src: url("./Krungthep/Krungthep.woff2") format("woff2"),
    url("./Krungthep/Krungthep.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

// @include font-face("Krungthep", "../assets/fonts/_fonts/Krungthep", woff woff2);
