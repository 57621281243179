@import "gerillass";
@import "../scss/variables.scss";

// text styles
.titleXL {
  font-family: Krungthep;
  font-size: 5rem;
  line-height: 140%;
  color: #ffffff;
  text-align: center;

  margin-top: 12vw;
  margin-bottom: 4vw;

  @include breakpoint(max, $tablet) {
    font-size: 4rem;
    line-height: 160%;
  }

  @include breakpoint(max, $mobile) {
    font-size: 3rem;
    line-height: 220%;
    width: auto;
  }
}

.titleL {
  font-family: Krungthep;
  font-size: 6rem;
  line-height: 140%;
  color: #ffffff;
  text-align: center;
  width: 70%;

  margin: 0 auto;

  position: relative;
  z-index: 200;

  @include breakpoint(max, $tablet) {
    font-size: 4rem;
    line-height: 160%;
  }

  @include breakpoint(max, $mobile) {
    font-size: 3.5rem;
  }
}

.titleM {
  font-family: Krungthep;
  font-size: 3vw;
  line-height: 100%;
  color: #ffffff;
  text-align: center;

  @include breakpoint(max, $tablet) {
    font-size: 2.5rem;
    line-height: 160%;
  }

  @include breakpoint(max, $mobile) {
    line-height: 300%;
    font-size: 2.5rem;
  }
}

.textXL {
  font-family: Krungthep;
  font-size: 2vw;
  line-height: 160%;
  color: #ffffff;
  text-align: center;
  width: 50vw;

  margin-bottom: 4vw;

  @include breakpoint(max, $tablet) {
    font-size: 1.5rem;
    line-height: 160%;

    width: 80vw;
  }

  @include breakpoint(max, $mobile) {
    font-size: 1.25rem;
    line-height: 200%;
    width: 100vw;
  }
}

.textL {
  font-family: Krungthep;
  font-size: 2vw;
  line-height: 180%;
  color: #ffffff;
  text-align: center;
  width: 80%;

  margin: 4vw auto;

  position: relative;
  z-index: 200;

  @include breakpoint(max, $tablet) {
    font-size: 3vw;
    line-height: 180%;
    width: 85%;
  }

  @include breakpoint(max, $mobile) {
    font-size: 1.25rem;
    line-height: 200%;
    width: 90%;
  }
}

.textM {
  font-family: Krungthep;
  font-size: 1.5vw;
  line-height: 180%;
  color: #ffffff;
  text-align: center;
  width: 80%;

  margin: 5vw 0;

  @include breakpoint(max, $tablet) {
    font-size: 2.25vw;
    line-height: 180%;
    width: 90%;

    br {
      display: none;
    }
  }

  @include breakpoint(max, $mobile) {
    font-size: 4vw;
    line-height: 200%;
    margin: 3vw 0;
  }
}
// text styles

.homeWrapper {
  width: 100vw;
  overflow-x: hidden;
}

.loadingPanel {
  @include position(fixed, 0 0 0 0);
  width: 100%;
  height: 100%;
  background: #000000;

  z-index: 10000;

  .loadingIndicator {
    position: absolute;
    @include center;
    width: 12vw;

    padding-right: 0.75rem;
    margin-top: 0.5rem;

    @include breakpoint(max, $tablet) {
      width: 20vw;
    }

    @include breakpoint(max, $mobile) {
      width: 50vw;
    }
  }

  .progress {
    color: #ffffff;
    position: absolute;
    @include center;

    font-size: 1rem;
    font-family: Krungthep;
  }
}

.banner {
  display: flex;
  align-items: center;
  justify-content: center;

  position: relative;
  z-index: 100;

  .bgVideo {
    width: 100vw;

    z-index: 100;
  }

  .bannerImg {
    position: absolute;
    width: 50%;
    z-index: 200;
    top: 4vw;

    @include breakpoint(max, $mobile) {
      top: 35vw;
      width: 95%;
      transform: rotate(-7deg);
    }
  }

  .bannerLogo {
    position: absolute;
    z-index: 200;
    top: 23.5vw;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    width: 80vw;

    z-index: 200;

    @include breakpoint(max, $mobile) {
      top: 80vw;
      width: 130vw;
    }
  }

  .bannerGradient {
    @include position(absolute, null 0 0 0);
    width: 100%;

    z-index: 150;

    @include breakpoint(max, $mobile) {
      background: linear-gradient(0deg, #000000 10%, rgba(0, 0, 0, 0));
    }
  }

  .bannerBlackGradient {
    @include position(absolute, null 0 0 0);
    width: 100%;
    height: 50vh;

    z-index: 150;

    @include breakpoint(max, $mobile) {
      background: linear-gradient(0deg, #000000 30%, rgba(0, 0, 0, 0));
    }
  }
}

.info {
  background: rgba($color: #000000, $alpha: 0.99);
  padding: 6vw 0;
  position: relative;
  z-index: 50;

  .downArrow {
    display: block;
    width: 12vw;
    margin: 0 auto 20vw auto;
    opacity: 0.5;
  }

  .imgWrapper {
    display: flex;
    align-items: flex-start;
    justify-content: center;

    padding: 2.5vw 0 0 0;

    @include breakpoint(max, $mobile) {
      flex-direction: column;
    }

    .infoBox,
    .infoArena {
      width: 50%;

      @include breakpoint(max, $mobile) {
        width: 100%;
      }
    }

    .infoBox {
      margin-right: 0;

      position: relative;
      z-index: 50;

      @include breakpoint(max, $mobile) {
        margin-left: -15vw;
      }
    }

    .infoArena {
      margin-left: -12vw;
      margin-top: 15vw;

      position: relative;
      z-index: 100;

      @include breakpoint(max, $mobile) {
        margin-left: 12vw;
        margin-top: -30vw;
      }
    }
  }

  .imgWrapperAbs {
    @include position(absolute, null 0 0 0);

    padding: 0 2vw;

    display: flex;
    align-items: center;
    justify-content: space-between;

    z-index: 100;

    @include breakpoint(max, $mobile) {
      font-size: 1rem;
      line-height: 200%;
      width: 90%;
      padding: 0;
      margin: 0 auto;
    }

    .infoCardRed,
    .infoCardBlue {
      width: 30%;

      @include breakpoint(max, $mobile) {
        width: 50%;
      }
    }

    .infoCardBlue {
      margin-bottom: -20vw;

      @include breakpoint(max, $mobile) {
        margin-bottom: -10vw;
        margin-left: -5vw;
      }
    }

    .infoCardRed {
      margin-bottom: 10vw;

      @include breakpoint(max, $mobile) {
        margin-top: -80vw;
        margin-right: -10vw;
      }
    }
  }
}

.teams {
  position: relative;
  z-index: 25;
  padding: 20vw 0 15vw 0;
  overflow: hidden;

  background-image: url("../img/bg-main.png");
  background-size: auto 100vh;
  background-attachment: fixed;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, #000000, rgba(0, 0, 0, 0.1));

    z-index: -1;
  }

  .teamsGradient {
    position: absolute;
    bottom: 0;
    left: 0;
    transform: rotateX(180deg);
    z-index: 50;
  }

  .teamRed {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    padding-top: 10vw;

    @include breakpoint(max, $mobile) {
      flex-direction: column;
      align-items: center;
    }

    .redPlayer {
      flex-basis: 50%;
      width: 50vw;

      @include breakpoint(max, $mobile) {
        flex-basis: 100%;
        width: 100vw;
      }
    }

    .right {
      flex-basis: 50%;

      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;

      @include breakpoint(max, $mobile) {
        flex-basis: 100%;
        align-items: center;
      }

      .redTeamFtw {
        width: 40vw;

        @include breakpoint(max, $tablet) {
          width: 45vw;
        }

        @include breakpoint(max, $mobile) {
          width: 90vw;
        }
      }
    }
  }

  .teamRedUtilities {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 7vw 0;

    @include breakpoint(max, $tablet) {
      padding: 5vw 0;
    }

    @include breakpoint(max, $mobile) {
      flex-direction: column-reverse;
      padding: 25vw 0;
    }

    .left {
      flex-basis: 50%;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      @include breakpoint(max, $mobile) {
        flex-basis: 100%;
        width: 100vw;
        margin-top: 120vw;
      }
    }

    .right {
      flex-basis: 50%;

      display: flex;
      align-items: center;
      justify-content: center;

      position: relative;

      @include breakpoint(max, $mobile) {
        flex-basis: 100%;
        width: 100vw;
      }

      .cardRedSupply {
        position: absolute;
        right: 23vw;
        width: 20vw;

        @include breakpoint(max, $tablet) {
          right: 21vw;
          width: 20vw;
        }

        @include breakpoint(max, $mobile) {
          width: 50vw;
          top: -10vw;
          right: 40vw;
        }
      }

      .cardRedInsider {
        position: absolute;
        top: -10vw;
        right: 5vw;
        width: 20vw;

        @include breakpoint(max, $tablet) {
          top: -5vw;
          width: 20vw;
        }

        @include breakpoint(max, $mobile) {
          width: 50vw;
          top: 40vw;
        }
      }
    }
  }

  .teamRedOctopus {
    display: flex;
    align-items: center;
    justify-content: center;

    padding: 8vw 0 0 0;

    @include breakpoint(max, $mobile) {
      padding: 8vw 0 10vw 0;
    }

    .octoWrapper {
      position: relative;

      display: flex;
      align-items: center;
      justify-content: center;

      .octoItem {
        @include position;
      }

      .octoGate {
        width: 60%;
        mix-blend-mode: lighten;

        @include breakpoint(max, $mobile) {
          width: 90%;
        }
      }

      .octoArm1 {
        width: 50vw;
        @include position(absolute, 7vw null null -2vw);

        @include breakpoint(min, $widescreen) {
          @include position(absolute, 5vw null null -15vw);
          -webkit-transform: translateX(7vw);
        }

        @include breakpoint(max, $mobile) {
          width: 60vw;
          @include position(absolute, 10vw null null -8vw);
        }
      }

      .octoArm2 {
        width: 13vw;
        @include position(absolute, 12vw null null 35vw);

        @include breakpoint(min, $widescreen) {
          @include position(absolute, 7vw null null 22vw);
          -webkit-transform: translateX(10vw);
        }

        @include breakpoint(max, $mobile) {
          width: 23vw;
          @include position(absolute, 17vw null null 27vw);
        }
      }

      .octoArm3 {
        width: 33vw;
        @include position(absolute, 13vw null null 45vw);

        @include breakpoint(min, $widescreen) {
          @include position(absolute, 7vw null null 32vw);
          -webkit-transform: translateX(10vw);
        }

        @include breakpoint(max, $mobile) {
          width: 33vw;
          @include position(absolute, 13vw null null 44vw);
        }
      }

      .octoArm4 {
        width: 43vw;
        @include position(absolute, 5vw null null 55vw);

        @include breakpoint(min, $widescreen) {
          @include position(absolute, 2vw null null 45vw);
          -webkit-transform: translateX(10vw);
        }

        @include breakpoint(max, $mobile) {
          width: 53vw;
          @include position(absolute, 10vw null null 55vw);
        }
      }
    }
  }

  .teamBlue {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    padding: 50vw 0 10vw 0;
    margin-top: -10vw;

    position: relative;

    @include breakpoint(max, $mobile) {
      flex-direction: column-reverse;
    }

    .dome {
      @include position(absolute, 0 null 0);
      background-repeat: no-repeat;
      height: 100%;
      width: 100%;
      background-size: contain;
      mix-blend-mode: color-dodge;

      @include breakpoint(max, $mobile) {
        width: 180%;
        transform: translateY(-18vh);
      }

      &:nth-child(1) {
        background-image: url("../img/dome-z-0.png");
        transform: translate3d(0, -29px, 0);
        opacity: 0.46;
      }

      &:nth-child(2) {
        background-image: url("../img/dome-z-1.png");
        transform: translate3d(-171px, 10px, 0);
      }

      &:nth-child(3) {
        background-image: url("../img/dome-z-2.png");
      }
    }

    .bluePlayer {
      flex-basis: 50%;
      width: 45vw;

      @include breakpoint(max, $mobile) {
        flex-basis: 100%;
        width: 100vw;
      }
    }

    .left {
      flex-basis: 50%;

      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: center;
      margin-top: 5vw;

      @include breakpoint(max, $mobile) {
        flex-basis: 100%;
        margin-top: -35vw;
        align-items: center;
      }

      .blueTeamStands {
        width: 55vw;
        margin-right: -10vw;

        @include breakpoint(max, $tablet) {
          width: 60vw;
        }

        @include breakpoint(max, $mobile) {
          width: 100vw;
          margin-right: 0;
        }
      }
    }
  }

  .teamBlueUtilities {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0 7vw 0;
    margin-top: -18vw;

    position: relative;

    @include breakpoint(max, $mobile) {
      flex-direction: column;
      margin-top: -38vw;
    }

    .right {
      flex-basis: 50%;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      @include breakpoint(max, $mobile) {
        flex-basis: 100%;
        margin-top: -10vh;
      }
    }

    .left {
      flex-basis: 50%;

      display: flex;
      align-items: center;
      justify-content: center;

      position: relative;
      z-index: 75;

      @include breakpoint(max, $mobile) {
        flex-basis: 100%;
        margin-top: 180vw;
      }

      .cardBlueAntivirus {
        position: absolute;
        right: 20vw;
        width: 20vw;

        @include breakpoint(max, $mobile) {
          top: -80vh;
          right: -15vw;
          width: 50vw;
        }
      }

      .cardBlueFirewall {
        position: absolute;
        top: -10vw;
        right: 5vw;
        width: 20vw;

        @include breakpoint(max, $mobile) {
          top: -65vh;
          right: -40vw;
          width: 50vw;
        }
      }
    }

    .teamBlueLightning {
      @include position(absolute, null 0 -25vw 0);
      width: 100vw;
      z-index: 50;

      @include breakpoint(max, $mobile) {
        @include position(absolute, null 0 -40vw 0);
      }
    }
  }

  .community {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    margin: 15vw 0 10vw 0;

    @include breakpoint(max, $tablet) {
      margin: 20vw 0;
    }

    @include breakpoint(max, $mobile) {
      margin: 40vw 0 0 0;
    }

    .inputWrapper {
      width: 56vw;

      display: flex;
      flex-direction: column;
      align-items: stretch;
      justify-content: center;

      @include breakpoint(max, $tablet) {
        width: 67vw;
      }

      @include breakpoint(max, $tabletMini) {
        width: 90vw;
      }

      .placeholderWrapper {
        position: relative;
        display: flex;

        .placeholder {
          position: absolute;
          top: 50%;
          left: 2vw;
          transform: translateY(-50%);

          color: #ffffff;
          font-family: Krungthep;
          font-size: 2rem;
          pointer-events: none;

          @include breakpoint(max, $tablet) {
            font-size: 2rem;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
          }

          @include breakpoint(max, $mobile) {
            font-size: 1rem;
          }
        }

        input {
          width: 100%;
          height: 7rem;
          border: 3px solid #ffffff;
          background: transparent;
          border-radius: 0;
          font-family: Krungthep;
          font-size: 2rem;
          padding-left: 2vw;
          color: #ffffff;
          text-align: left;

          @include breakpoint(max, $tablet) {
            text-align: center;
          }

          @include breakpoint(max, $mobile) {
            border: 2px solid #ffffff;
            height: 3.5rem;
            font-size: 1.25rem;
            padding-left: 1rem;
          }
        }
      }

      .emailAgreement {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 1rem;

        margin-top: 2vw;
        cursor: pointer;

        @include breakpoint(max, $mobile) {
          width: 85vw;
          gap: 0.75rem;
        }

        .checkbox {
          border: 3px solid #ffffff;
          width: 2vw;
          height: 2vw;

          position: relative;

          flex-shrink: 0;

          @include breakpoint(max, $tablet) {
            width: 4vw;
            height: 4vw;
          }

          @include breakpoint(max, $mobile) {
            border: 3px solid #ffffff;
            width: 5vw;
            height: 5vw;
            flex-shrink: 0;
          }

          .checkboxPin {
            width: 60%;
            height: 60%;
            background: #ffffff;

            position: absolute;
            @include center;
          }
        }

        .label {
          font-family: Krungthep;
          font-size: 1.5rem;
          line-height: 140%;
          color: #ffffff;
          text-align: left;
          cursor: pointer;
          max-width: 80vw;

          @include breakpoint(max, $tablet) {
            font-size: 1.25rem;
            line-height: 160%;
          }

          @include breakpoint(max, $mobile) {
            font-size: 14px;
            line-height: 140%;
            text-align: left;
          }
        }
      }

      .submitBtn {
        $oHeight: 10px;
        $xSlide: 5px;

        height: 7rem;
        background: rgba($color: #000000, $alpha: 0.6);
        backdrop-filter: blur(16px);
        border: 3px solid #ffffff;
        margin-top: 5rem;
        position: relative;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        @include breakpoint(max, $mobile) {
          height: 15vw;
          margin-top: 3rem;
        }

        &:hover {
          .overflowWrapper {
            &:nth-child(1) {
              p {
                transform: translateY($oHeight) translateX(0px);
              }
            }

            &:nth-child(3) {
              p {
                transform: translateY(-15px) translateX(0px);
              }
            }
          }
        }

        .overflowWrapper {
          height: $oHeight;
          overflow: hidden;

          display: flex;
          align-items: center;
          justify-content: center;

          &:nth-child(1) {
            p {
              transform: translateY($oHeight) translateX($xSlide);
              transition: 0.4s all ease;
            }
          }

          &:nth-child(3) {
            height: 15px;
            p {
              transform: translateY(-15px) translateX(-$xSlide);
              transition: 0.4s all ease;
            }
          }
        }

        .btnText {
          margin: 0;
          padding: 0;
          font-family: Krungthep;
          font-size: 2rem;

          color: #ffffff;

          @include breakpoint(max, $mobile) {
            font-size: 5vw;
          }
        }
      }
    }
  }
}
