@import "gerillass";
@import "../scss/variables.scss";

.header {
  @include position(fixed, 0 0 null 0);

  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 2rem;

  z-index: 500;

  .mobileBg {
    @include position(absolute, 0 null null 0);
    height: 30vh;
    width: 100vw;
    background: linear-gradient(180deg, #000000 10%, rgba(0, 0, 0, 0));
    display: none;
    pointer-events: none;

    @include breakpoint(max, $mobile) {
      display: block;
    }
  }

  @include breakpoint(max, $tablet) {
    z-index: 5000;
  }

  @include breakpoint(max, $mobile) {
    padding: 1rem;
    height: auto;
    align-items: flex-start;
  }

  .logo {
    cursor: pointer;
    z-index: 150;

    @include breakpoint(max, $mobile) {
      width: 45%;
    }
  }

  .navigation {
    background: rgba($color: #000000, $alpha: 0.6);
    backdrop-filter: blur(16px);
    color: #ffffff;
    border: 1px solid #f4f4f4;
    display: flex;
    padding: 0.75rem;
    position: relative;

    .navPin {
      @include position(fixed, 6rem 0 null null);
      width: 17vw;
      display: block;

      pointer-events: none;
    }

    .navItem {
      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3) {
        .navText:after {
          content: "";
          @include position(absolute, 0 0 null null);
          width: 2px;
          height: 2rem;
          background: #ffffff;
        }
      }

      .navText {
        padding: 0.5rem 1.5rem;
        font-family: Krungthep;
        position: relative;

        &:hover {
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
  }

  .navigationMobile {
    .hamburgerWrapper {
      background: rgba($color: #000000, $alpha: 0.6);
      backdrop-filter: blur(16px);
      border: 1px solid #f4f4f4;
      width: 3rem;
      height: 3rem;
      position: relative;
      cursor: pointer;

      z-index: 150;

      @include breakpoint(max, $mobile) {
        width: 2rem;
        height: 2rem;
      }

      .hamburger {
        position: absolute;
        @include center;

        @include breakpoint(max, $mobile) {
          width: 80%;
          height: 80%;
        }
      }
    }

    .menuMobile {
      @include position(fixed, 0 0 0 0);
      width: 100%;
      height: 100%;
      background: transparent;

      z-index: 100;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .menuBg {
        @include position(absolute, 0 0 0 0);
        background: #000000;
      }

      .menuItem {
        position: relative;
        color: #ffffff;

        display: flex;
        align-items: center;
        justify-content: flex-end;

        &:nth-child(even) {
          justify-content: flex-end;
          margin-right: 5vw;

          @include breakpoint(max, $mobile) {
            margin-right: 5vw;
          }
        }

        &:nth-child(odd) {
          justify-content: flex-start;
          margin-left: 5vw;

          @include breakpoint(max, $mobile) {
            margin-left: 7vw;
          }
        }

        p {
          font-size: 5vw;
          padding: 7vw 21vw;
          font-family: Krungthep;
          text-decoration: underline;
          cursor: pointer;

          white-space: nowrap;

          z-index: 20;

          @include breakpoint(max, $mobile) {
            font-size: 7vw;
            padding: 10vw 25vw;
          }
        }

        img {
          width: 30vw;
          position: absolute;
          opacity: 0.5;

          z-index: 10;

          @include breakpoint(max, $mobile) {
            width: 40vw;
          }
        }
      }
    }
  }

  .kickstarterBtn {
    position: fixed;
    bottom: 2.5vw;
    right: 2.5vw;

    background: rgba($color: #000000, $alpha: 0.6);
    backdrop-filter: blur(16px);
    padding: 1.25rem 4rem;
    border: 3px solid #f4f4f4;
    transition: 0.2s all ease;
    cursor: pointer;

    &:hover {
      box-shadow: 0px 0px 30px #a7a7a7;
      background: #000000;
    }

    p {
      font-family: Krungthep;
      font-size: 1.5vw;
      line-height: 140%;
      color: #ffffff;
      text-align: center;
    }
  }
}
